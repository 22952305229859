import React from 'react'
import { Container } from 'components/common'
import contact from 'assets/illustrations/contact.svg'
import { Wrapper, Details, Thumbnail } from './styles'
import ContactInfo from './ContactInfo'

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details>
      <ContactInfo />
    </Details>
  </Wrapper>
)
