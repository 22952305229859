import React from 'react'
import { Layout, SEO } from 'components/common'
import { Intro, Dinner, Banner, Contact } from 'components/landing'

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <Dinner />
    <Banner />
    <Contact />
  </Layout>
)
