import React from 'react'
import axios from 'axios'
import { Button, Input } from 'components/common'
import { Error, Center, InputField } from './styles'

export default () => (
  <div>
    <h1>はやしやホルモン館</h1>
    <h2>住所</h2>
    <h3>〒380-0823 長野県長野市南千歳２丁目14-9 小池ビル1F</h3>
    <h2>連絡先</h2>
    <h3>026-224-7701</h3>
    <h2>営業時間</h2>
    <h3>17:00〜23:00（日曜定休）</h3>
  </div>
)
