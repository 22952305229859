import React from 'react'
import { Header } from 'components/theme'
import { Container } from 'components/common'
import hayashiya from 'assets/images/hayashiya.jpg'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>はやしやホルモン館</h1>
        <h4>新鮮なお肉のみを扱う焼き肉店</h4>
      </Details>
      <Thumbnail>
        <img src={hayashiya} alt="はやしやホルモン館" />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
)
