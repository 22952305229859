import React from 'react'
import { Container, Button } from 'components/common'
import dinner from 'assets/images/dinner3.jpg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Dinner = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dinner} alt="七輪で焼き肉" />
      </Thumbnail>
      <Details>
        <h1>ディナー</h1>
        <p>
          焼き肉専門店として、牛、豚、鳥の様々な部位の提供をしており、備長炭の七輪でお肉を焼いて食べることが出来ます。また、当店ではレギュラーメニュー以外に限定のメニューなどもございます。予算内での飲み放題コースなどのサービス提供も可能ですので、どうぞお気軽にお申し付けください。
        </p>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
