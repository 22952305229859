import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Container, Card } from 'components/common'
import starIcon from 'assets/icons/star.svg'
import forkIcon from 'assets/icons/fork.svg'
import { Wrapper, Grid, Item, Content, Stats } from './styles'

export const Banner = () => (
  <Link to="/coupon">
    <Wrapper as={Container} id="coupon">
      <h1>クーポンはこちら</h1>
    </Wrapper>
  </Link>
)
